<template>
  <div class="sec-back">
    <section>
      <b-container fluid class="d-flex justify-content-center banner-politique">
        <b-row align-v="center">
          <b-col>
            <h1 class="text-center banner-text">
              Conditions générales d'utilisation et mentions légales du site Mes
              Meilleurs Menus
            </h1>
          </b-col>
        </b-row>
      </b-container>
    </section>

    <section>
      <b-container>
        <p>En vigueur au 15/02/2023</p>
        <p>
          Les présentes conditions générales d’utilisation (dites «
          <strong>CGU</strong> ») ont pour objet l’encadrement juridique des
          modalités de mise à disposition du site Mes Meilleurs Menus dont l’URL
          est https://www.mmmenus.fr (ci-après « le site ») et de ses services
          par l’Association des Meilleurs Mangeurs et de définir les conditions
          d’accès et d’utilisation des services par «
          <strong>l’Utilisateur</strong> ». Les présentes CGU sont accessibles
          sur le site à la rubrique « Conditions générales d’utilisation et
          Mentions Légales».
        </p>
        <p>
          La poursuite de la navigation sur ce site vaut acceptation sans
          réserve des dispositions et conditions d’utilisation qui suivent. La
          version actuellement en ligne de ces conditions d’utilisation est la
          seule opposable pendant toute la durée d’utilisation du site et
          jusqu’à ce qu’une nouvelle version la remplace.
        </p>
        <p><strong>Article 1 : Les mentions légales</strong></p>
        <p>
          L’édition et la direction de la publication du site
          https://www.mmmenus.fr est assurée par le Président de l’association
          Mes Meilleurs Menus dont le siège est au 2 rue de la plaine, 68170
          Rixheim.
        </p>
        <p>Adresse e-mail&nbsp;: <b>mmm</b>@mmmenus.fr</p>
        <p>
          L’hébergeur du site est la société OVH, dont le siège social est situé
          au 2 rue de Kellermann 59100 Roubaix<b
            ><span
              style="
                font-size: 12pt;
                font-family: 'arial', sans-serif;
                letter-spacing: 0.3pt;
                background: white none repeat scroll 0% 0%;
              "
            ></span
            >.</b
          >
        </p>
        <p><strong>ARTICLE 2 : Accès au site</strong></p>
        <p>
          Le site https://www.mmmenus.fr propose au grand public un service
          gratuit d’aide pratique et personnalisée pour mieux manger pour
          prendre soin de sa santé et lutter contre la crise climatique,
          notamment en proposant des produits alimentaires et des menus
          hebdomadaires bons pour la santé et pour la lutte contre la crise
          climatique. Les recommandations diététiques du site sont en cohérence
          avec les recommandations nutritionnelles officielles et notamment
          celles de l’ANSES.
        </p>
        <p>
          Le site est accessible gratuitement en tout lieu à tout Utilisateur
          ayant un accès à Internet. Tous les frais supportés par l’Utilisateur
          pour accéder au service (matériel informatique, logiciels, connexion
          Internet, etc.) sont à sa charge.
        </p>
        <p><strong>ARTICLE 3 : Collecte et protection des données</strong></p>
        <p>
          Le site assure à l’Utilisateur une collecte et un traitement
          d’informations personnelles, notamment dans le respect de la vie
          privée conformément à la loi n°78-17 du 6 janvier 1978 relative à
          l’informatique, aux fichiers et aux libertés.
        </p>
        <p>
          En application de la réglementation applicable aux données à caractère
          personnel, les utilisateurs disposent des droits suivants :
        </p>
        <ul>
          <li>
            le droit d’accès : ils peuvent exercer leur droit d’accès, pour
            connaître les données personnelles les concernant, en écrivant à
            l’adresse électronique ci-dessous mentionnée. Dans ce cas, avant la
            mise en œuvre de ce droit, la Plateforme peut demander une preuve de
            l’identité de l’utilisateur afin d’en vérifier l’exactitude ;
          </li>
          <li>
            le droit de rectification : si les données à caractère personnel
            détenues par la Plateforme sont inexactes, ils peuvent demander la
            mise à jour des informations ;
          </li>
          <li>
            le droit de suppression des données : les utilisateurs peuvent
            demander la suppression de leurs données à caractère personnel,
            conformément aux lois applicables en matière de protection des
            données ;
          </li>
          <li>
            le droit à la limitation du traitement : les utilisateurs peuvent de
            demander à la Plateforme de limiter le traitement des données
            personnelles conformément aux hypothèses prévues par le RGPD ;
          </li>
          <li>
            le droit de s’opposer au traitement des données : les utilisateurs
            peuvent s’opposer à ce que leurs données soient traitées
            conformément aux hypothèses prévues par le RGPD ;
          </li>
          <li>
            le droit à la portabilité : ils peuvent réclamer que la Plateforme
            leur remette les données personnelles qu’ils ont fournies pour les
            transmettre à une Plateforme similaire.
          </li>
        </ul>
        <p>
          L’Utilisateur peut exercer ces droits par courrier électronique à
          l’adresse&nbsp;: <b>mmm</b>@mmmenus.fr. Toute demande doit être
          accompagnée de la photocopie d’un titre d’identité en cours de
          validité signé et faire mention de l’adresse à laquelle l’éditeur
          pourra contacter le demandeur. La réponse sera adressée dans le mois
          suivant la réception de la demande. Ce délai d’un mois peut être
          prolongé de deux mois si la complexité de la demande et/ou le nombre
          de demandes l’exigent.
        </p>
        <p>
          De plus, et depuis la loi n°2016-1321 du 7 octobre 2016, les personnes
          qui le souhaitent, ont la possibilité d’organiser le devenir de leurs
          données après leur décès. Pour plus d’information sur le sujet, vous
          pouvez consulter le site Internet de la CNIL : https://www.cnil.fr/.
        </p>
        <p>
          L’Utilisateur est informé que les données collectées peuvent être
          partagées avec des sociétés spécialisées dans le cadre d’un contrat de
          prestation de service pour l’exécution du service décrit à l’ARTICLE
          2. Tout autre usage est proscrit.Ces sociétés prestataires disposent
          d’un accès limité aux données de l’utilisateur, dans le cadre de
          l’exécution de ces prestations, et ont l’obligation contractuelle de
          les utiliser en conformité avec les dispositions de la réglementation
          applicable en matière protection des données à caractère personnel ;
        </p>
        <p>
          Le site conserve vos données pour la durée nécessaire pour vous
          fournir ses services ou son assistance. Dans la mesure raisonnablement
          nécessaire ou requise pour satisfaire aux obligations légales ou
          réglementaires, régler des litiges, empêcher les fraudes et abus ou
          appliquer nos modalités et conditions, nous pouvons également
          conserver certaines de vos informations si nécessaire, même après que
          vous ayez fermé votre compte ou que nous n’en ayons plus besoin pour
          vous fournir nos services.
        </p>
        <p><strong>&nbsp;</strong></p>
        <p><strong>ARTICLE 4 : Propriété intellectuelle</strong></p>
        <p>
          Les marques, logos, signes ainsi que tous les contenus du site
          (textes, images, son…) font l’objet d’une protection par le Code de la
          propriété intellectuelle et plus particulièrement par le droit
          d’auteur. &nbsp;
        </p>
        <p>
          La marque Mes meilleurs Menus est une marque déposée. Toute
          représentation et/ou reproduction et/ou exploitation partielle ou
          totale de cette marque, de quelque nature que ce soit, est totalement
          prohibée.
        </p>
        <p>
          Les illustrations sur le site internet et les menus peuvent être des
          dessins, des photos ou des images de synthèse de personnes ou de repas
          ayant été par générées par intelligence artificielle ou correspondant
          à des prises de vue.
        </p>
        <p>
          Les marques, logos, signes ainsi que tous les contenus du site
          (textes, images, son…) &nbsp;sont la propriété pleine et entière de
          l’éditeur ou de ses partenaires. Toute reproduction, représentation,
          utilisation ou adaptation, sous quelque forme que ce soit, de tout ou
          partie de ces éléments, y compris les applications informatiques, sans
          l’accord préalable et écrit de l’éditeur, sont strictement interdites.
          Le fait pour l’éditeur de ne pas engager de procédure dès la prise de
          connaissance de ces utilisations non autorisées ne vaut pas
          acceptation desdites utilisations et renonciation aux poursuites.
        </p>
        <p><strong>ARTICLE 5 : Responsabilité</strong></p>
        <p>
          Les sources des informations diffusées sur le site
          https://www.mmmenus.fr sont réputées fiables mais le site ne garantit
          pas qu’il soit exempt de défauts, d’erreurs ou d’omissions.
        </p>
        <p>
          Les informations communiquées sont présentées à titre indicatif et
          général sans valeur contractuelle. Les calculs des apports
          nutritionnels du site sont donnés à titre indicatif et sont fondés sur
          des données nutritionnelles moyennes, notamment la base de données
          publique CIQUAL et peuvent de ce fait ne pas correspondre exactement
          aux teneurs en nutriment constatés pour tel ou tel aliment acheté dans
          le commerce. Malgré des mises à jour régulières, le site
          https://www.mmmenus.fr ne peut être tenu responsable de la
          modification des dispositions administratives et juridiques survenant
          après la publication. De même, le site ne peut être tenu responsable
          de l’utilisation et de l’interprétation de l’information contenue dans
          ce site.
        </p>
        <p>
          Le site https://www.mmmenus.fr ne peut être tenu pour responsable
          d’éventuels virus qui pourraient infecter l’ordinateur ou tout
          matériel informatique de l’Internaute, suite à une utilisation, à
          l’accès, ou au téléchargement provenant de ce site.
        </p>
        <p>
          L’éditeur du site ne pourra être tenu responsable en cas de poursuites
          judiciaires à votre encontre :
        </p>
        <p>
          – du fait de l’usage du site ou de tout service accessible via
          Internet ;
        </p>
        <p>
          – du fait du non-respect par vous des présentes conditions générales.
        </p>
        <p>
          La responsabilité du site ne peut être engagée en cas de force majeure
          ou du fait imprévisible et insurmontable d’un tiers.
        </p>
        <p>
          Les photographies de produits, accompagnant leur description, ne sont
          pas contractuelles et n’engagent pas l’éditeur.
        </p>
        <p><strong>ARTICLE 6 : Liens hypertextes</strong></p>
        <p>
          Des liens hypertextes peuvent être présents sur le site. L’Utilisateur
          est informé qu’en cliquant sur ces liens, il sortira du site
          https://www.mmmenus.fr. Ce dernier n’a pas de contrôle sur les pages
          web sur lesquelles aboutissent ces liens et ne saurait, en aucun cas,
          être responsable de leur contenu.
        </p>
        <p>
          La mise en place par les utilisateurs de tous liens hypertextes vers
          tout ou partie du site est autorisée par l’éditeur. Tout lien devra
          être retiré sur simple demande de l’éditeur. Toute information
          accessible via un lien vers d’autres sites n’est pas publiée par
          l’éditeur. L’éditeur ne dispose d’aucun droit sur le contenu présent
          dans ledit lien.
        </p>
        <p><strong>ARTICLE 7 : Cookies</strong></p>
        <p>
          L’Utilisateur est informé que lors de ses visites sur le site, un
          cookie peut s’installer automatiquement sur son logiciel de
          navigation.
        </p>
        <p>
          Les cookies sont de petits fichiers stockés temporairement sur le
          disque dur de l’ordinateur ou du smartphone de l’Utilisateur par votre
          navigateur et qui sont nécessaires à l’utilisation du site
          https://www.mmmenus.fr. Les cookies ne contiennent pas d’information
          personnelle et ne peuvent pas être utilisés pour identifier quelqu’un.
          Un cookie contient un identifiant unique, généré aléatoirement et donc
          anonyme. Certains cookies expirent à la fin de la visite de
          l’Utilisateur, d’autres restent.
        </p>
        <p>
          L’information contenue dans les cookies est utilisée pour améliorer le
          site https://www.mmmenus.fr.
        </p>
        <p>
          En naviguant sur le site, L’Utilisateur les accepte.L’Utilisateur
          pourra désactiver ces cookies par l’intermédiaire des paramètres
          figurant au sein de son logiciel de navigation.
        </p>
        <p><strong>ARTICLE 8&nbsp;: Gestion du site</strong></p>
        <p>Pour la bonne gestion du site, l’éditeur pourra à tout moment :</p>
        <p>
          – suspendre, interrompre ou limiter l’accès à tout ou partie du site,
          réserver l’accès au site, ou à certaines parties du site, à une
          catégorie déterminée d’internautes ;
        </p>
        <p>
          – supprimer toute information pouvant en perturber le fonctionnement
          ou entrant en contravention avec les lois nationales ou
          internationales ;
        </p>
        <p>– suspendre le site afin de procéder à des mises à jour.</p>
        <p><strong>&nbsp;</strong></p>
        <p>
          <strong
            >ARTICLE 9 : Droit applicable et juridiction compétente</strong
          >
        </p>
        <p>
          Les présentes conditions d’utilisation du site sont régies par la loi
          française et soumises à la compétence des tribunaux du siège social de
          l’éditeur, sous réserve d’une attribution de compétence spécifique
          découlant d’un texte de loi ou réglementaire particulier.
        </p>
        <p>
          Pour toute question relative à l’application des présentes conditions
          générales, information sur les produits et menus présentés sur le site
          ou par e-mail, ou concernant le site lui-même, vous pouvez envoyer un
          message à l’adresse suivante : <b>mmm</b>@mmmenus.fr.
        </p>
      </b-container>
    </section>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.banner-politique {
  background-clip: border-box;
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("../assets/lalimentation-positive-et-durable/courses.png");
  /*linear-gradient(rgba(0, 0, 0, .5), rgba(0, 0, 0, .5)), top 150px center fixed no-repeat; */
  margin: 50px 0;

  width: 100%;
  height: 700px;
  background-attachment: fixed;
  background-size: cover;
  background-position: bottom 200px center;
  background-repeat: no-repeat;
}

@media only screen and (max-width: 991px) {
  .sec-back {
    padding-bottom: 250px;
  }
}

.banner-text {
  font-family: "Amanise";
  font-size: clamp(36px, 4vw + 29px, 84px);
  font-weight: 400;
  color: white;
}

h4 {
  font-family: "Amanise";
  font-size: 32px;
  font-weight: 300;
  color: rgb(0, 76, 64);
}

p,
ul {
  font-family: "TangoSans";
  font-size: 18px;
  font-weight: 300;
  color: rgb(0, 0, 0);
  margin: 0 0 18px;
}
</style>
