<template>
  <div>
    <section>
      <b-container
        fluid
        class="d-flex justify-content-center banner-alimentation"
      >
        <b-row align-v="center">
          <b-col>
            <h1 class="text-center banner-text" style="margin-top: 250px">
              L'Alimentation Positive et Durable
            </h1>
          </b-col>
        </b-row>
      </b-container>
    </section>
    <section class="sec-back" style="padding-bottom: 40px">
      <b-container>
        <div>
          <h2
            class="amanise"
            style="
              color: #004c40;
              font-size: clamp(32px, 2vw + 25px, 48px);
              margin: 70px;
            "
          >
            Une alimentation méditerranéene, à faible indice glycémique et
            bas-carbone
          </h2>
        </div>
        <section class="tangosans card-propre" style="margin-bottom: 50px">
          <div style="padding: 25px">
            <h3>Quels Objectifs</h3>
            <p>
              L'objectif global de Mes Meilleurs Menus est de rendre possible au
              quotidien la mise en place d'une alimentation positive et durable.
              Mes Meilleurs Menus combinent les bienfaits du régime
              Méditerranéen, en limitant la consommation de viandes , tout en
              veillant à avoir des aliments à indice glycémique bas. Nous
              voulons vous aider à manger sainement , tout en diminuant la
              production de gaz à effet de serre afin de lutter contre le
              réchauffement climatique. Ce service est entièrement gratuit car
              nous souhaitons en faire bénéficier au plus grand nombre afin
              d'avoir collectivement un impact plus fort dans la lutte contre la
              crise climatique. Notre but est également de vous faire prendre
              «&nbsp;goût&nbsp;» à ces bonnes habitudes alimentaires. Arrêtons
              de culpabiliser, prenons notre destin en mainet gardons chaque
              jour le plaisir de déguster de bons petits plats tout en essayant
              de prendre un peu plus soin de notre planète.
            </p>
            <h3>
              Pourquoi est-il devenu urgent de changer notre façon de nous
              alimenter?
            </h3>
            <p>
              Pour répondre à cette interrogation, dressons le bilan actuel de
              l'alimentation occidentale. Sans aucun doute, cela nous aidera à
              nous motiver afin de changer certaines de nos (mauvaises)
              habitudes.
            </p>
            <p>
              L'alimentation est fondamentale pour la santé. Pour preuve,
              d'après une très grande étude épidémiologique publié dans
              <em>Lancet</em> (Londres, Angleterre), on peut estimer que les
              facteurs de risque alimentaires causent plus de 11 millions décès
              par an dans le monde (chiffre pour 2017) et on a toutes les
              raisons de penser que ces chiffres ne vont faire que croître dans
              les prochaines années [1,2]. Les maladies cardiovasculaires sont
              la principale cause de décès liés à une mauvaise alimentation (10
              millions), suivies des cancers (1 million environ), puis le
              diabète de type 2 (340 000 environ). Il faut également savoir que
              toujours d'après cette étude, plus de 5 millions de décès liés à
              l'alimentation (45 % du total) surviennent chez des adultes de
              moins de 70 ans.
            </p>
            <p>
              En cause, l'augmentation de la taille des portions, la plus grande
              densité énergétique, l'alimentation industrielle en excès, la
              grande disponibilité de l'alimentation, et l'évolution des prix
              alimentaires qui sont tous des éléments qui favorisent des repas
              trop riches en calories. Ce type de régime est couramment appelé
              «&nbsp;régime occidental&nbsp;», à l'origine d'une prise de poids
              excessive. Ainsi, l'obésité est un véritable problème de santé
              publique, que ce soit en France ou au niveau mondial. En France,
              une personne sur deux est en surpoids et 17% de la population
              souffre d'obésité. Les maladies cardio-vasculaires sont la 1<sup
                >ère </sup
              >cause de mortalité chez les plus de 65 ans et les femmes, et plus
              de 4 millions de personnes sont atteints de diabète de type 2 et
              ces chiffres ne font que s'aggraver (Sources : enquête ENNS;
              enquête INCA 3 (Anses); enquête Esteban (Santé publique France)).
            </p>
            <p>Quels sont les principaux facteurs de risques alimentaires ?</p>
            <ul>
              <li>
                un faible apport en graines complètes(provenant des céréales du
                petit-déjeuner, du pain, du riz, des pâtes, des biscuits, etc…),
              </li>
              <li>une faible consommation de fruits et légumes</li>
              <li>un apport élevé en sodium</li>
              <li>
                Une trop forte consommation de sucre ajouté, de viande rouge,
                d'acides gras trans, de viandes transformés (charcuterie)
              </li>
              <li>
                Une trop faible consommation de céréales, légumineuses, de
                produits de la mer ou de calcium [1]
              </li>
            </ul>
            <p>
              De plus, un nombre croissant d'études montre clairement que le
              risque de cancer colorectal pourrait être réduit en tenant compte
              de ces facteurs de risques dans nos habitudes alimentaires, et
              notamment en réduisant notre consommation de viande rouge, abats
              et charcuterie [3,4].
            </p>

            <h3>Où est notre planète ?</h3>
            <p>
              Au niveau climatique, nous savons que les émissions mondiales de
              dioxyde de carbone (CO2) fossile ont augmenté de 67 % entre 1990
              et 2018. En un siècle, la température moyenne au niveau mondial a
              augmenté de plus de 1°C et le niveau moyen des mers a augmenté de
              9 cm entre 1993 et 2019.
            </p>
            <p>
              L'agriculture intensive, telle qu'elle est pratiquée depuis les
              années 1960, a certes des rendements extraordinaires, mais elle
              épuise les sols et pollue l'environnement. Pour maintenir le même
              rendement, il faut toujours augmenter les quantités d'intrants
              (eau, matériel, pesticides et fertilisants). Le rôle de
              l'agriculture dans les émissions de gaz à effet de serre est
              primordial, et notamment du fait de la poussée de la production
              dans les pays du sud, Chine et Inde en tête. L'émission de méthane
              par les ruminants y joue un rôle majeur. La prise de conscience
              récente des limites des ressources naturelles et de la pollution
              des sols, de l'air et de l'eau, pousse vers une nouvelle
              agriculture, l'agriculture durable.Dans un rapport en date de
              2013, l'INRAE nous rappelle qu'en France, l'agriculture représente
              2% du PIB mais environ 20% (en intégrant les émissions
              énergétiques) du total des émissions de gaz à effet de serre (GES,
              CITEPA 2012), ce qui est énorme. Ainsi, il est primordial que
              l'agriculture contribue à l'effort général de réduction des
              émissions de gazs et c'est donc à nous, citoyens de ce monde, de
              pousser tous les acteurs, des politiciens à la grande
              distribution, à diminuer cette production.
            </p>
            <p>
              L'agriculture peut participer à l'amélioration du bilan net des
              émissions de gaz à effet de serre via trois leviers :
            </p>
            <ul>
              <li>
                la réduction des émissions de protoxyde d'azote (N2O) et de
                méthane (CH4),
              </li>
              <li>le stockage de carbone dans les sols et dans la biomasse,</li>
              <li>
                la production d'énergie à partir de biomasse (biocarburants,
                biogaz) réduisant les émissions par effet de substitution à des
                énergies fossiles.
              </li>
            </ul>
            <p>
              Concernant le réchauffement climatique, dans le 6ème rapport du
              GIEC, un chapitre particulier est consacré au méthane. Les
              émissions de ce gaz liées à l'activité agricole ont augmenté de
              près de 20% en 17 ans. C'est un constat alarmant car bien que ce
              gaz persiste moins longtemps dans l'atmosphère que le CO2, il a un
              pouvoir de réchauffement bien supérieur. En effet, il est estimé
              que sur une période de 20 ans, une tonne de méthane a un pouvoir
              de réchauffement 84 fois plus élevé qu'une tonne de CO2. Le
              méthane est le deuxième gaz à effet de serre qui contribue le plus
              au réchauffement climatique, après le dioxyde de carbone. D'après
              un rapport de l'ONU publié en mai 2021, le fait de réduire dès
              maintenant les émissions de méthane permettrait d'éviter un
              réchauffement de la planète de près de 0,3 °C à l'horizon 2045.
            </p>

            <p>
              Les émissions de méthane issues de l'activité humaine sont dues en
              premier lieu à l'élevage de bétail et la combustion de la
              biomasse. Si nous consommons moins de viande, et notamment de la
              viande de bœuf, la production baissera automatiquement, ce qui
              permettra de diminuer de façon significative la production de
              méthane et donc le réchauffement de notre planète. L'élevage fait
              en effet l'objet de débats sociétaux importants et est étudié par
              les scientifiques : le rapport FAO pointe ses impacts sur
              l'environnement, en particulier sa contribution pour 14,5% aux
              émissions mondiales de gaz à effet de serre (GES). C'est donc un
              levier sur lequel, en mangeant moins de viande, nous pouvons
              chacun agir. Privilégions la qualité plutôt que la quantité.
            </p>
            <div class="d-flex justify-content-center py-3">
              <a
                ><router-link to="/inscription"
                  ><button class="btn sign-btn">
                    Je m'inscris gratuitement
                    <font-awesome-icon icon="fa-solid fa-right-to-bracket" />
                  </button>
                </router-link>
              </a>
            </div>
            <h3>Notre Statégie</h3>
            <p>
              Nos menus sont réfléchis, sélectionnés puis mis en place à partir
              de résultats scientifiques publiés dans des revues scientifiques
              sérieuses. Nous nous basons également sur les Meta analyses
              publiées dans les meilleures revues scientifiques internationales
              et bien entendu nous tenons compte des grandes recommandations
              officielles qui se basent sur les rapports de la communauté
              scientifique. C'est ainsi que nous sommes arrivés à la conclusion
              que la meilleure alimentation, qui combine à la fois bienfaits
              pour notre santé mais également pour notre climat se base sur 3
              grands principes&nbsp;:
            </p>
            <ul>
              <li>
                L'utilisation des recettes basées sur le régime méditerranéen.
              </li>
              <li>
                Une alimentation avec un indice glycémique bas, c'est-à-dire
                avec un apport limité en sucre « rapide ».
              </li>
              <li>
                Une réduction de la consommation de viandes, essentiellement la
                viande rouge de mauvaise qualité et/ou transformée.
              </li>
            </ul>
            <h3>Pourquoi le choix du régime Méditerranéen ?</h3>
            <p>
              Le régime méditerranéen a été choisi comme le meilleur régime à
              suivre en 2021, selon le «&nbsp;US News and World Report&nbsp;».
              Il est à présent universellement reconnu comme un des modèles
              d'alimentation de référence pour une bonne santé.
            </p>
            <p>
              Ce terme de régime méditerranéen est utilisé pour identifier un
              régime né dans le bassin méditerranéen il y a plusieurs milliers
              d'années. Il est le résultat d'une tradition culinaire forte, se
              basant sur les ressources cultivées localement. Ses effets sur la
              santé ont été démontrés par plus de 50 ans de recherche. Les
              données scientifiques démontrent qu'il peut prévenir de
              l'infarctus, de l'accident vasculaire cérébral mais aussi du
              diabète, certains cancers, les démences type Alzheimer et même la
              dépression.
            </p>
            <p>
              Malheureusement les tendances socio-économiques ont éloigné la
              population de ce mode de vie. Une mise à jour récente de 2022
              révèle une augmentation du nombre d'essais cliniques testant le
              régime méditerranéen au cours de la dernière décennie(5). Ces
              essais, dans une grande majorité,continuent de démontrer, sur de
              grands échantillons de personnes, les effets bénéfiques du régime
              méditerranéen. Ces effets pourraient être principalement liés à
              ses propriétés anti-inflammatoires et antioxydantes ainsi qu'à son
              efficacité en termes de contrôle de la prise de poids et de
              diminution de l'obésité.
            </p>
            <div class="d-flex justify-content-center">
              <img
                class="bg-light"
                src="../assets/lalimentation-positive-et-durable/Olivier.png"
              />
            </div>
            <h3>Qu'apporte le régime méditerranéen ?</h3>
            <p>
              – <strong><em>Les produits de la mer</em></strong> (sardines,
              saumon, maquereau, thon, etc…) apportent une grande diversité de
              nutriments, et notamment des acides gras omega-3. L'huile d'olive
              extra vierge est la première source de graisse dans le régime
              méditerranéen. Elle apporte un nombre important de molécules
              actives et globalement bénéfiques (6).
            </p>
            <p>
              – <strong><em>Les fruits et légumes&nbsp;</em></strong
              >sont mis en avant dans le régime méditerranéen. Les oranges,
              mandarines, les grenades, les baies, les figues et les raisins
              sont les fruits les plus consommés et une source de fibres
              alimentaires, de potassium, de vitamine C, de polyphénols
              (principalement des flavones) et de terpènes (7). Les légumes
              doivent être principalement de saison. Les plus représentatifs
              sont les légumes verts frais, les tomates, les aubergines, les
              choux, les radis, l'ail, les oignons, les épinards et la laitue.
              Bien que ces aliments soient une source essentielle de nutriments
              (par exemple, fibres alimentaires, potassium, cuivre, magnésium,
              folate, vitamines -A, -B6, -C, -E, -K), les composés phénoliques
              (principalement les flavonoïdes) sont les molécules bio actives
              importantes ici également (8). Plusieurs études ont démontré
              qu'une consommation élevée de légumes ou de fruits diminue le
              risque de mortalité toutes causes confondues, en terme de maladie
              coronarienne, d'accident vasculaire cérébral, de diabète de type
              2, de cancer du côlon rectal et de prise de poids (9-11).
            </p>
            <p>
              <strong
                ><em
                  >Les légumineuses, les céréales et les oléagineux</em
                ></strong
              >
              sont également très consommées dans le régime méditerranéen. Les
              céréales peuvent être consommés en tant qu'aliment propre (le riz
              et les flocons d'avoine) ou comme des ingrédients d'aliments
              transformés (le pain, les pâtes). Les oléagineux comprennent les
              amandes, les noisettes, les noix et les pistaches. Parmi les
              légumineuses, les plus représentatives sont les lentilles, les
              haricots et les pois chiches. Dans l'ensemble, ces aliments sont
              une source précieuse de fibres, de folate, de vitamine B6, de
              magnésium, de potassium et de cuivre (8, 11). En particulier, la
              consommation de noix est cruciale car ces aliments sont uniques
              pour leur teneur en acides gras mono-insaturés et en acides gras
              polyinsaturés, en particulier l'acide linoléique (omega-6),
              linolénique, les flavonoïdes, etc… (12). L'effet bénéfique de la
              consommation de noix, légumineuses, et de certaines céréales a
              principalement un impact sur l'incidence des maladies
              cardiovasculaires, du diabète et du syndrome métabolique (10, 13,
              14).
            </p>
            <p>
              <strong>En résumé</strong>, la consommation de ces produits
              cultivés à l'origine sur le pourtour méditerranéen a une action
              préventive et curative et permet de lutter contre l'apparition de
              divers types de maladies chroniques. Le régime méditerranéen
              maintient l'organisme en bonnes santé grâce à un apport diversifié
              et riche en différents nutriments essentiels dont nous avons
              besoins au quotidien. C'est un régime santé.
            </p>
            <div class="d-flex justify-content-center py-3">
              <a
                ><router-link to="/inscription"
                  ><button class="btn sign-btn">
                    Je m'inscris gratuitement
                    <font-awesome-icon icon="fa-solid fa-right-to-bracket" />
                  </button>
                </router-link>
              </a>
            </div>
            <h3>Pourquoi le choix du régime à indice glycémique bas ?</h3>
            <p>
              L'index ou indice glycémique (IG) permet de classer les aliments,
              et notamment les sucres, en fonction de leur capacité à élever la
              glycémie (taux de sucre dans le sang). Plus un aliment a un indice
              glycémique élevé, plus il fait monter la glycémie et plus de
              l'insuline sera libérée. Il s'en suit des risques de coups de
              pompes après les repas riches en sucres, des fringales et surtout
              un stockage accru de graisses. A moyen terme, la consommation de
              ces aliments à indice glycémique élevé peut conduire au
              développement d'une obésité couplée à un véritable diabète de type
              2. Au contraire, manger des repas à faible indice glycémique
              permet d'éviter les pics de glycémie, et ainsi se débarrasser des
              coups de fatigue post-prandiale, du stockage des graisses et des
              risques de diabète de type 2. C'est la stratégie que nous avons
              choisie&nbsp;: Limiter les aliments à fort indice glycémique et
              globalement limiter la prise de sucre dit «&nbsp;rapide&nbsp;» et
              au contraire privilégier les sucres lents et certains nutriments
              «&nbsp;complets&nbsp;». Pour cela, nous nous sommes basés sur les
              recommandations de l'Organisation mondiale pour la santé (OMS) et
              de l'ANSES ainsi que sur les publications scientifiques récentes
              qui suggèrent de diminuer les apports en sucres et notamment les
              apports en sucre ajoutés à notre alimentation (sucres libres). En
              effet, une directive de l'OMS recommande à tous les individus
              (enfants et adultes) de réduire l'apport en sucres libres à moins
              de 10 %, idéalement à 5 %, de l'apport énergétique total (16).
            </p>
            <p>
              Dix pourcents des apports énergétiques totaux représentent une
              quantité d'environ 50 grammes (12 cuillères à café) environ par
              jour de sucres libres pour une ration de 2000 kcal. L'OMS suggère
              même d'essayer de limiter la prise de sucres ajoutés (libres) à
              moins de 25g par jour. Les «&nbsp;sucres libres&nbsp;» sont les
              monosaccharides (glucose, fructose) et les disaccharides
              (saccharose ou sucre de table) ajoutés aux aliments et aux
              boissons par le fabricant, le cuisinier ou le consommateur, ainsi
              que les sucres naturellement présents dans le miel, les sirops,
              les jus de fruits.&nbsp;
            </p>
            <p>
              Par exemple, une cuillère à soupe de ketchup contient
              approximativement 4 grammes (à peu près une cuillère à café) de
              sucres libres et une canette de soda en contient jusqu'à 40
              grammes (environ 10 cuillères à café). Il a été déclaré par les
              organismes officiels (OMS) qu'une baisse d'au moins 50% des
              apports en sucre, sont jugés nécessaires afin de mettre un terme à
              l'augmentation du diabète et de l'obésité et de diminuer la charge
              des décès prématurés imputables aux maladies non transmissibles.
            </p>
            <p>
              En soi, le régime méditerranéen limite déjà les apports en sucre
              rapide s'il est bien défini et bien suivi. Mais il nous semblait
              essentiel de prendre clairement position au niveau du type de
              sucre et de sa quantité consommée au quotidien, ce qui n'est pas
              clairement le cas dans le régime méditerranéen. La littérature
              scientifique démontre que réduire le pourcentage des apports en
              sucre (et du coup augmenter le % des apports en lipides) permet
              notamment une perte de poids et globalement le retour à une
              meilleure santé. Ainsi, <em>Mes Meilleurs Menus</em>
              ont pour objectif de ne pas dépasser une prise de 25g de
              «&nbsp;sucre libre&nbsp;» par jour afin d'être en cohérence avec
              les grandes recommandations, ce qui aura sans aucun doute pour
              effet d'optimiser les bénéfices santé.
            </p>

            <h3>
              Quels sont les effets d'une diminution de la prise de sucres ?
            </h3>
            <p>
              Pour notre organisme, Il existe trois principales sources
              d'énergie apportées par les macronutriments. Ces macronutriments
              sont les protéines, les lipides (graisses) et les glucides
              (sucres). Chaque nutriment à sa place dans notre alimentation. Le
              régime à indice glycémique bas, consiste à diminuer la
              consommation d'aliments qui contiennent des sucres et/ou de bien
              choisir le type de sucre que l'on consomme. Le principal objectif
              est d'équilibrer les apports énergétiques entre les glucides et
              les lipides, de consommer des aliments élaborés avec de la farine
              «&nbsp;complètes&nbsp;» ou «&nbsp;semi-complètes&nbsp;», de garder
              des apports énergétiques«&nbsp;classique&nbsp;», au niveau des
              protéines (entre 12% et 20% au maximum).
            </p>
            <p>
              Pourquoi diminuer les sucres&nbsp;? tout simplement car il est
              compliqué de ne pas prendre du poids sans limiter les apports en
              sucre, et notamment en sucre dit «&nbsp;rapide&nbsp;», qui sont
              les sucres généralement ajoutés à nos aliments de façon
              artificielle. Il est clairement notifié dans le rapport de l'ANSES
              de 2016 que «&nbsp;dans l'état actuel des connaissances, il est
              important de mentionner qu'un excès d'apport énergétique sous
              forme de glucides associé à un apport énergétique trop élevé
              augmente la formation des acides gras saturés qui sont alors
              stockés sous formes de triglycérides. Cette situation favorise
              l'augmentation de la triglycéridémie et accroit le risque de
              pathologies cardio-métaboliques (17). A la vue des études
              scientifiques réalisées, le rapport de l'ANSES conclu que qu'une
              alimentation apportant de l'ordre de 40 à55 % de l'énergie sous
              forme de glucides est l'intervalle d'apport optimal pour la
              réduction du risque de prise de poids et de troubles métaboliques,
              de diabète de type 2, de maladies cardiovasculaires et de certains
              cancers. Ainsi, nous avons décidé de nous placer dans la
              fourchette basse de 40-45% d'apport énergétique en provenance des
              sucres, à la vue de plusieurs études montrant une meilleure
              efficacité de ce type de régimes (ex. réf. 18, 19). De la même
              manière, il existe toute une bibliographie qui propose que le
              régime paléolithique, parfois appelé «&nbsp;régime de l'homme des
              cavernes&nbsp;», est basé sur le principe selon lequel la
              consommation d'aliments qui étaient disponibles pour les premiers
              humains favoriserait une santé optimale et préviendra diverses
              maladies (20,21). L'une des théories fondamentales derrière ce
              régime est que les systèmes alimentaires modernes, les techniques
              de production et de transformation sont préjudiciables à la santé
              humaine. Ces affirmations sont tout à fait compatibles avec le
              fait de diminuer les apports en glucides, puisque très clairement
              pendant des milliers d'année l'alimentation de nos ancêtres
              étaient limitée en sucres rapides et plutôt riches en lipides.
            </p>
            <p>&nbsp;</p>
            <p><strong>Sucres rapides et sucres lents</strong></p>
            <p>
              Il faut savoir que l'on trouve les glucides essentiellement sous
              deux formes&nbsp;:
            </p>
            <p>&nbsp;</p>
            <p>
              <strong
                >Les glucides simples, c'est-à-dire les sucres rapides</strong
              >. Ils apportent de l'énergie sur un temps beaucoup plus court,
              car ils passent rapidement dans le compartiment sanguin. On les
              retrouve dans les aliments au goût sucré comme les bonbons, le
              chocolat, les sodas, les gâteaux ou tout simplement le sucre de
              table (saccharose=fructose + glucose). Le problème, c'est qu'ils
              provoquent une augmentation importante de la glycémie et induise
              une sécrétion d'insuline qui est t l'hormone qui entraine
              notamment le stockage des sucres et des graisses. Ainsi, pour
              éviter de prendre du poids, il faut limiter cette consommation de
              sucre rapide et essayer de na pas en consommer plus de 25g par
              jour.
            </p>
            <p>&nbsp;</p>
            <p>
              <strong
                >La deuxième grande classe est les glucides complexes</strong
              >, plus simplement appelés les sucres
              «&nbsp;lents&nbsp;»&nbsp;:Leur digestion est lente et du coup
              apporte de l'énergie sur un temps plus long. On les retrouve
              principalement dans tous les féculents type pâtes, riz, pain,
              pomme de terre et légumineuses. C'est dans cette catégorie que se
              trouve les sucres à indice glycémique faible. Pour optimiser au
              maximum il faut consommer des farines dites
              «&nbsp;complètes&nbsp;». La lettre T suivie du numéro différencie
              les farines. T est une abréviation pour «&nbsp;type&nbsp;» et
              indique la teneur en minéraux pour 100 g de matière sèche.&nbsp;
              Ainsi une farine T45 ou T55 est une farine blanche très pure, qui
              ne contient quasiment pas d'écorce de blé, et donc peu de fibres,
              mais beaucoup de glucides. Une farine complèteT110 (contient le
              germe + une partie importante de l'enveloppe) ou intégrale T150
              (intégralité du grain) contient trois fois plus de minéraux (fer,
              potassium, magnésium…) et cinq fois plus de fibres qu'une T45. Ce
              n'est qu'à partir de la T80 que la teneur en fibres, minéraux,
              vitamines B9, protéines, devient intéressant. Il en va de-même
              pour le riz. Le riz complet est nutritivement bien plus
              intéressant que le riz blanc, même si la teneur en sucre est assez
              similaire. Ainsi, la quantité de fibres, vitamines et minéraux est
              plus importante dans le riz complet, et la présence des fibres
              permet de ralentir la digestion des sucres lents, ce qui est une
              bonne chose en terme de régulation de la glycémie (22).
            </p>
            <div class="d-flex justify-content-center">
              <img
                class="bg-light"
                src="../assets/lalimentation-positive-et-durable/alimentation-positive-durable.jpg"
              />
            </div>
            <h3>Conclusion</h3>
            <p>
              Mes Meilleurs Menus ont été développés scientifiquement pour
              répondre aux exigences de l'alimentation au 21ème siècle et
              notamment pour pouvoir relever deux grands défis :
            </p>
            <ul>
              <li>
                mieux manger pour améliorer notre santé en limitant le
                développement des maladies chroniques et en renforcant notre
                organisme contre les infections (virus, bactéries),
              </li>
              <li>
                mieux manger pour limiter les émissions de gaz à effet de serre.
              </li>
            </ul>
            <p>
              Les menus que nous vous proposons au quotidien amélioreront vos
              connaissances des bons aliments et vous permettront d'améliorer
              certaines de vos habitudes alimentaires, par exemple en retirant
              de votre liste de courses les produits ultra-transformés et riches
              en sucres ajoutés. Vous vous sentirez mieux et le climat de notre
              planète aussi.
            </p>
            <div class="d-flex justify-content-center py-3">
              <a
                ><router-link to="/inscription"
                  ><button class="btn sign-btn">
                    Je m'inscris gratuitement
                    <font-awesome-icon icon="fa-solid fa-right-to-bracket" />
                  </button>
                </router-link>
              </a>
            </div>
            <div>
              Références:
              <div class="blockquote-style">
                <blockquote>
                  <ol>
                    <li>
                      GBD 2017 Diet Collaborators. Health effects of dietary
                      risks in 195 countries, 1990–2017: a systematic analysis
                      for the Global Burden of Disease Study 2017. Lancet. 2019;
                      93(10184):1958–72. Epub 2019Apr 8.
                      https://doi.org/10.1016/S0140-6736(19)30041-8 PMID:
                      30954305.
                    </li>
                    <li>
                      &nbsp;Fadnes LT, Økland JM, Haaland ØA, Johansson KA.
                      Estimating impact of food choices on life expectancy: A
                      modeling study.PLoS Med. 2022 Feb 8;19(2):e1003889. doi:
                      10.1371/journal.pmed.1003889. eCollection 2022 Feb. PMID:
                      3513406
                    </li>
                    <li>
                      Norat T, Bingham S, Ferrari P, Slimani N, Jenab M, Mazuir
                      M, Overvad K, Olsen A, Tjonneland A, Clavel F,
                      Boutron-Ruault MC, et al. Meat, fish, and colorectal
                      cancer risk. The European prospective investigation into
                      cancer and nutrition. J Natl Cancer Inst 2005; 15 : 906–16
                    </li>
                    <li>
                      Veettil SK, et al. Role of Diet in Colorectal Cancer
                      Incidence: Umbrella Review of Meta-analyses of Prospective
                      Observational Studies. JAMA Netw Open. 2021.
                    </li>
                    <li>
                      &nbsp;Finicelli M, Di Salle A, Galderisi U, Peluso G.The
                      Mediterranean Diet: An Update of the Clinical Trials.
                      Nutrients. 2022 Jul 19;14(14):2956. doi:
                      10.3390/nu14142956
                    </li>
                    <li>
                      &nbsp;Schwingshackl L., Christoph M., Hoffmann G. Effects
                      of Olive Oil on Markers of Inflammation and Endothelial
                      Function—A Systematic Review and Meta-Analysis. Nutrients.
                      2015;7:7651–7675. doi: 10.3390/nu7095356.
                    </li>
                    <li>
                      &nbsp;Schwingshackl L., Morze J., Hoffmann G.
                      Mediterranean diet and health status: Active ingredients
                      and pharmacological mechanisms. Br. J. Pharmacol.
                      2020;177:1241–1257. doi: 10.1111/bph.14778.
                    </li>
                    <li>
                      &nbsp;Delgado A.M., Vaz Almeida M.D., Parisi S. Chemistry
                      of the Mediterranean Diet. Springer; Cham, Switzerland:
                      2017
                    </li>
                    <li>
                      &nbsp;Schwingshackl L., Schlesinger S., Devleesschauwer
                      B., Hoffmann G., Bechthold A., Schwedhelm C., Iqbal K.,
                      Knuppel S., Boeing H. Generating the evidence for risk
                      reduction: A contribution to the future of food-based
                      dietary guidelines. Proc. Nutr. Soc. 2018;77:432–444. doi:
                      10.1017/S0029665118000125.
                    </li>
                    <li>
                      &nbsp;Bechthold A., Boeing H., Schwedhelm C., Hoffmann G.,
                      Knuppel S., Iqbal K., De Henauw S., Michels N.,
                      Devleesschauwer B., Schlesinger S., et al. Food groups and
                      risk of coronary heart disease, stroke and heart failure:
                      A systematic review and dose-response meta-analysis of
                      prospective studies. Crit. Rev. Food. Sci. Nutr.
                      2019;59:1071–1090. doi: 10.1080/10408398.2017.1392288.
                    </li>
                    <li>
                      &nbsp;Schlesinger S., Neuenschwander M., Schwedhelm C.,
                      Hoffmann G., Bechthold A., Boeing H., Schwingshackl L.
                      Food Groups and Risk of Overweight, Obesity, and Weight
                      Gain: A Systematic Review and Dose-Response Meta-Analysis
                      of Prospective Studies. Adv. Nutr. 2019;10:205–218.
                    </li>
                    <li>
                      Finicelli M., Squillaro T., Di Cristo F., Di Salle A.,
                      Melone M.A.B., Galderisi U., Peluso G. Metabolic syndrome,
                      Mediterranean diet, and polyphenols: Evidence and
                      perspectives. J. Cell. Physiol. 2019;234:5807–5826. doi:
                      10.1002/jcp.27506.
                    </li>
                    <li>
                      &nbsp;Zec M., Glibetic M. Reference Module in Food
                      Science. Elsevier; Oxford, UK: 2018. Health Benefits of
                      Nut Consumption.
                    </li>
                    <li>
                      &nbsp;Kelly J.H., Jr., Sabate J. Nuts and coronary heart
                      disease: An epidemiological perspective. Br. J. Nutr.
                      2006;96((Suppl. 2)):61–67. doi: 10.1017/BJN20061865
                    </li>
                    <li>
                      &nbsp;Xiao Li, Dalia Perelman,Ariel K Leong, Gabriela
                      Fragiadakis, Christopher D Gardner, Michael P Snyder.
                      Distinct factors associated with short-term and long-term
                      weight loss induced by low-fat or low-carbohydrate diet
                      intervention. Cell Rep Med. 2022 Dec 20;3(12):100870.doi:
                      10.1016/j.xcrm.2022.100870
                    </li>
                    <li>
                      &nbsp;OMS: Guideline: sugars intake for adults and
                      children.
                      https://www.who.int/publications/i/item/9789241549028
                    </li>
                    <li>
                      Rapport de l'ANSES:
                      www.anses.fr/fr/system/files/NUT2012SA0103Ra-2.pdf
                    </li>
                    <li>
                      Shai I, Schwarzfuchs et al. Weight loss with a
                      low-carbohydrate, Mediterranean, or low-fat diet. N Engl J
                      Med. 2008 Jul 17;359(3):229-41. doi:
                      10.1056/NEJMoa0708681.
                    </li>
                    <li>
                      &nbsp;Schwarzfuchs D, Golan R, Shai I. Four-year follow-up
                      after two-year dietary interventions. N Engl J Med. 2012
                      Oct 4;367(14):1373-4. doi: 10.1056/NEJMc1204792.
                    </li>
                    <li>
                      Gupta L, Khandelwal D, Lal PR, Kalra S, Dutta
                      D.Palaeolithic Diet in Diabesity and Endocrinopathies – A
                      Vegan's Perspective.Eur Endocrinol. 2019 Aug;15(2):77-82.
                      doi: 10.17925/EE.2019.15.2.77.
                    </li>
                    <li>
                      &nbsp;Ungar P, Grine F, Teaford M. Diet in early Homo: a
                      review of the evidence and a new model of adaptive
                      versatility. Ann Rev Anthropol. 2006;35:209–28.
                    </li>
                    <li>
                      &nbsp;Imai S, Fukui M, Kajiyama S. Effect of eating
                      vegetables before carbohydrates on glucose excursions in
                      patients with type 2 diabetes. J Clin BiochemNutr.
                      2014;54:7–11. doi: 10.3164/jcbn.13-67.
                    </li>
                  </ol>
                </blockquote>
              </div>
            </div>
          </div>
        </section>
      </b-container>
    </section>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.banner-alimentation {
  background-clip: border-box;
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("../assets/lalimentation-positive-et-durable/courses.png");
  /*linear-gradient(rgba(0, 0, 0, .5), rgba(0, 0, 0, .5)), top 150px center fixed no-repeat; */

  width: 100%;
  height: 600px;
  /* background-attachment: fixed; */
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

.banner-text {
  font-family: "Amanise";
  font-size: clamp(36px, 4vw + 29px, 84px);
  font-weight: 400;
  color: white;
}

.card-propre {
  background-color: rgba(133, 187, 47, 0.28);
  border-radius: 20px;
  word-wrap: break-word;
}

.sec-back {
  background: url("../assets/meilleure-menus.png");
  background-attachment: fixed;
  background-size: cover;
  background-position: center center;
}

/* Media query for phone-sized screens */
@media only screen and (max-width: 991px) {
  .sec-back {
    padding-bottom: 250px;
  }
}

h2 {
  font-family: "Amanise";
  color: #004c40;
  font-size: clamp(32px, 2vw + 25px, 50px);
  padding: 5px;
  margin-bottom: 30px;
  text-align: center;
}

h3 {
  font-family: "Amanise";
  color: #004c40;
  font-size: clamp(28px, 2vw + 21px, 42px);
  padding: 5px;
  margin: 5px;
}

section div img {
  max-height: 750px;
  max-width: 750px;
  height: auto;
  width: 100%;
}

.sign-btn {
  color: white;
  background-color: rgb(133, 187, 47);
  padding: 20px 40px;
}

.blockquote-style {
  border-left: 1px solid rgb(226, 38, 88);
  padding-left: 20px;
  margin: 20px;
}
</style>
