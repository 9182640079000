<template>
  <div>
    <section>
      <b-container fluid class="d-flex justify-content-center banner-faq">
        <b-row align-v="center">
          <b-col>
            <h1 class="text-center banner-text">
              Mes Meilleurs Menus en Pratique
            </h1>
          </b-col>
        </b-row>
      </b-container>
    </section>

    <section class="sec-back">
      <b-container>
        <h2>
          Des professionelles de santé vous aident à mettre mes meilleurs menus
          en pratique
        </h2>
        <p>
          Si vous le souhaitez, voici les coordonnées de dietéticien.ne.s qui
          peuvent vous aider au quotidien pour faire entrer Mes Meilleurs Menus
          dans votre vie pour plus de saveurs, pour une meilleure santé et un
          meilleur climat. Tous proposent des consultations en visio:
        </p>
        <b-card class="mb-3">
          <b-row class="align-items-center">
            <b-col cols="12" md="4" class="text-center mb-3 p-3">
              <img
                src="../assets/expert/veronique.jpg"
                alt="Dr. Anna-Isabel SCHLAGOWSKI"
                class="rounded-circle img-fluid expert-img"
              />
              <h4>Dr. Anna-Isabel SCHLAGOWSKI</h4>
              <h5>Experte en Sport et Nutrition</h5>
            </b-col>
            <b-col cols="12" md="4" class="mb-3 p-3">
              <h3>Site internet</h3>
              <a href="https://www.exclusivsport.com/" target="_blank"
                >coach sportif Strasbourg | France | ExclusivSport</a
              >
            </b-col>
            <b-col cols="12" md="4" class="mb-3 p-3">
              <h3>Réservez une consultation en Visio</h3>
              <a
                href="https://www.exclusivsport.com/nous-contacter"
                target="_blank"
                >https://www.exclusivsport.com/nous-contacter.</a
              >
            </b-col>
          </b-row>
        </b-card>

        <b-card class="mb-3">
          <b-row class="align-items-center">
            <b-col cols="12" md="4" class="text-center mb-3 p-3">
              <img
                src="../assets/expert/veronique-beck.png"
                alt="Veronique Beck"
                class="rounded-circle img-fluid expert-img"
              />
              <h4>Veronique Beck</h4>
              <h5>Diététicienne Nutritionniste</h5>
            </b-col>
            <b-col cols="12" md="4" class="mb-3 p-3">
              <h3>Site internet</h3>
              <a href="https://dietical.fr/" target="_blank" rel="noopener"
                >Cabinet de Diététique-Nutrition</a
              >
              <p>
                Après 10 années dans l’industrie agroalimentaire, Véronique Beck
                s’est formée aux médecines douces, puis est devenue
                diététicienne-nutritionniste. Elle propose des consultations à
                son cabinet, des interventions dans les écoles et des
                conférences grand public.
              </p>
            </b-col>
            <b-col cols="12" md="4" class="mb-3 p-3">
              <h3>Réservez une consultation en Visio</h3>
              <p>
                Veronique BECK-YVARS, Diététicien à Sausheim : Prenez RDV en
                ligne (doctolib.fr)
              </p>
            </b-col>
          </b-row>
        </b-card>

        <b-card class="mb-3">
          <b-row class="align-items-center">
            <b-col cols="12" md="4" class="text-center mb-3 p-3">
              <img
                src="../assets/expert/portrait2.png"
                alt="Audrey Kolbert"
                class="rounded-circle img-fluid expert-img"
              />
              <h4>Audrey Kolbert</h4>
              <h5>Diététicienne Nutritionniste</h5>
            </b-col>
            <b-col cols="12" md="4" class="mb-3 p-3">
              <h3>Site internet</h3>
              <a href="https://audiet.fr/" target="_blank"
                >&nbsp;https://audiet.fr/</a
              >
            </b-col>
            <b-col cols="12" md="4" class="mb-3 p-3">
              <h3>Réservez une consultation en Visio</h3>
              <p>https://audiet.fr/</p>
            </b-col>
          </b-row>
        </b-card>

        <b-card class="mb-3">
          <b-row class="align-items-center">
            <b-col cols="12" md="4" class="text-center mb-3 p-3">
              <img
                src="../assets/expert/Emilie.png"
                alt="Emilie Pierrat"
                class="rounded-circle img-fluid expert-img"
              />
              <h4>Emilie Pierrat</h4>
              <h5>Diététicienne Nutritionniste</h5>
            </b-col>
            <b-col cols="12" md="4" class="mb-3 p-3">
              <h3>Site Internet</h3>
              <a
                href="https://www.emiliepierrat-dieteticienne-professeurapa.fr/"
                target="_blank"
                >Diététicienne | Pierrat Émilie Diététicienne Nutritionniste |
                Reiningue
                (emiliepierrat-dieteticienne-professeurapa.fr)&nbsp;</a
              >
            </b-col>
            <b-col cols="12" md="4" class="mb-3 p-3">
              <h3>Réservez une consultation en Visio</h3>
              <a
                href="https://www.doctolib.fr/dieteticien/reiningue/emilie-pierrat"
                target="_blank"
                >https://www.doctolib.fr/dieteticien/reiningue/emilie-pierrat</a
              >
            </b-col>
          </b-row>
        </b-card>

        <b-card class="mb-3">
          <b-row class="align-items-center">
            <b-col cols="12" md="4" class="text-center mb-3 p-3">
              <img
                src="../assets/expert/Detouree.jpg"
                alt="Oriane Garteiser"
                class="rounded-circle img-fluid expert-img"
              />
              <h4>Oriane Garteiser</h4>
              <h5>Diététicienne Nutritionniste</h5>
            </b-col>
            <b-col cols="12" md="4" class="mb-3 p-3">
              <h3>Site Internet</h3>
              <a href="https://www.garteiser-dieteticienne.com/"
                >https://www.garteiser-dieteticienne.com/</a
              >
            </b-col>
            <b-col cols="12" md="4" class="mb-3 p-3">
              <h3>Réservez une consultation en Visio</h3>
              <a
                title="http://www.garteiser-dieteticienne.com/horaires-contacts"
                href="http://www.garteiser-dieteticienne.com/horaires-contacts"
                data-ogsc=""
                >www.garteiser-dieteticienne.com/horaires-contacts</a
              >
            </b-col>
          </b-row>
        </b-card>
      </b-container>
    </section>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.banner-faq {
  background-clip: border-box;
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("../assets/expert/professionnels-scaled.jpg");
  /*linear-gradient(rgba(0, 0, 0, .5), rgba(0, 0, 0, .5)), top 150px center fixed no-repeat; */
  padding: 50px 0 50px 0;

  width: 100%;
  height: 700px;
  background-attachment: fixed;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

.banner-text {
  font-family: "Amanise";
  font-size: clamp(36px, 4vw + 29px, 84px);
  font-weight: 400;
  color: white;
}

.sec-back {
  background: url("../assets/meilleure-menus.png");
  background-attachment: fixed;
  background-size: cover;
  background-position: center center;
}

@media only screen and (max-width: 991px) {
  .sec-back {
    padding-bottom: 250px;
  }
}

h2 {
  font-family: "Amanise";
  color: #004c40;
  font-size: clamp(32px, 2vw + 25px, 50px);
  padding: 5px;
  margin-bottom: 30px;
  text-align: center;
}

h3 {
  font-family: "TangoSans";
  font-size: 18px;
  font-weight: 300;
  color: #00803e;
}

h4 {
  font-family: "TangoSans";
  font-size: 18px;
  font-weight: 200;
  color: rgb(0, 76, 64);
}

a {
  color: rgb(0, 76, 64);
  font-family: "TangoSans";
  font-size: 18px;
  font-weight: 300;
}

p,
h5 {
  font-family: "TangoSans";
  font-size: 18px;
  font-weight: 300;
  color: rgb(0, 0, 0);
  margin: 0 0 18px;
}

img {
  max-height: 240px;
  width: auto;
  margin-bottom: 20px;
}
</style>
