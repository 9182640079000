<template>
  <b-container>
    <b-row>
      <b-col class="invalid-page">
        <h1>Page Introuvable</h1>
        <p>
          La page que vous recherchez est introuvable. Elle pourrait avoir été
          supprimée, renommée, ou pourrait n’avoir jamais existé.
        </p>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {};
</script>

<style scoped>
.invalid-page {
  margin: 250px 0 300px 0;
}
</style>
