<template>
  <!-- <b-modal size="xl" ref="modalPdf" centered hide-footer hide-header> -->
  <div>
    <div class="container" id="pdf-gen">
      <!-- Page 1 -->
      <div>
        <div>
          <img class="logo-image" src="../assets/app/logo-version-allongee-baseline-couleur.png" />
        </div>
        <div class="d-flex flex-column justify-content-center align-items-center">
          <h1>
            Mes Meilleurs Menus de la semaine {{ formattedDate }}
            <!-- <span> {{ date }}</span> -->
          </h1>
          <h1 class="d-flex justify-content-center">
            Préparés pour {{ infos.prenom }}{{ members }}
            <!-- <span> {{ user.name }}</span> -->
          </h1>
        </div>
        <div class="d-flex flex-column text-center">
          <h1 class="amanise p-2">Cette semaine c’est notamment :</h1>
          <div class="row">
            <div class="col-3 p-4" v-for="(jour, index) in semaine" :key="index">
              <div class="jour">{{ jour }}</div>
              <div class="image-plat">image du plat</div>
              <div class="plat-title">titre du plat</div>
            </div>
            <div class="col-3 p-4">
              <div class="jour">Petits-déjeuner</div>
              <div class="image-plat">image du plat</div>
              <div class="plat-title">titre du plat</div>
            </div>
          </div>
        </div>
        <div class="page-break-before d-flex flex-column text-center">
          <h1 class="amanise p-2">Sommaire</h1>
          <div class="row">
            <div class="col-4">
              <img class="sommaire-image" src="@/assets/accueil/MMMFichier-24x-8.png" />
            </div>
            <div class="col-4">
              <div class="partie">Petits-déjeuners</div>
              <div class="partie">Collations</div>
              <div class="partie">Boissons</div>
              <div class="partie">Condiments</div>
            </div>
            <div class="col-4">
              <div class="partie">Mes Meilleurs Menus</div>
              <div class="partie">Couverture de mes besoins</div>
              <div class="partie">La liste de course qui va bien</div>
            </div>
          </div>
        </div>
      </div>

      <!-- page-2 -->
      <div>
        <div class="partie-div">
          <div class="d-flex flex-row justify-content-between">
            <img class="logo-image" src="../assets/app/logo-version-allongee-baseline-couleur.png" />
            <!--<div class="row partie p-3">Retour page 1</div>-->
            <div>
              <a class="d-flex flex-column justify-content-center align-items-center"
                href="https://www.Leetchi.com/c/mes-meilleurs-menus" target="_blank">
                <p class="p-0 m-0">Je fais un don sur</p>
                <p class="p-0 m-0">Leetchi.com/c/mes-meilleurs-menus</p>
              </a>
            </div>
          </div>
          <h1 class="amanise p-4">Mes Petits-déjeuners</h1>
          <p>
            Le petit déj’ est essentiel pour bien démarrer la journée. Mes
            Meilleurs Menus vous propose les petits déjeuners suivants:
          </p>
          <div class="breakfast-style">
            <div class="p-2">
              <div class="row table-header">
                <div class="col-3 th-style-2">
                  {{ petitDej[0].petitDejeuner["Goût"] }}
                </div>
                <div class="col-1 th-style-2">Quantité</div>
                <div class="col-1 th-style-2">Unité</div>
                <div class="col-3 th-style-2">
                  {{ petitDej[0].petitDejeuner["NomFamPetitDejeuner"] }}
                </div>
                <div class="col-4 th-style-2">
                  {{ petitDej[0].petitDejeuner["DurPrep"] }} min -
                  {{ petitDej[0].petitDejeuner["Diff"] }}
                </div>
              </div>
              <div>
                <div class="row">
                  <div class="col-3">image-plat</div>
                  <div class="col-9 row">
                    <div class="col-6">
                      <div class="row" v-for="(ingredient, index) in petitDej[0][
                        'ingredientsPetitDejeuner'
                      ]" :key="index">
                        <div class="col-3 th-style">
                          {{ ingredient.quantity }}
                        </div>
                        <div class="col-2 th-style">
                          {{ ingredient.unit }}
                        </div>
                        <div class="col-7 th-style">
                          {{ ingredient.ingredient.alim_nom_fr }}
                        </div>
                      </div>
                    </div>
                    <div class="col-6 th-style">
                      {{ petitDej[0].petitDejeuner.TextRecette }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="p-2">
              <div class="row table-header">
                <div class="col-3 th-style-2">
                  {{ petitDej[1].petitDejeuner["Goût"] }}
                </div>
                <div class="col-1 th-style-2">Quantité</div>
                <div class="col-1 th-style-2">Unité</div>
                <div class="col-3 th-style-2">
                  {{ petitDej[1].petitDejeuner["NomFamPetitDejeuner"] }}
                </div>
                <div class="col-4 th-style-2">
                  {{ petitDej[1].petitDejeuner["DurPrep"] }} min -
                  {{ petitDej[1].petitDejeuner["Diff"] }}
                </div>
              </div>
              <div>
                <div class="row">
                  <div class="col-3">image-plat</div>
                  <div class="col-9 row">
                    <div class="col-6">
                      <div class="row" v-for="(ingredient, index) in petitDej[1][
                        'ingredientsPetitDejeuner'
                      ]" :key="index">
                        <div class="col-3 th-style">
                          {{ ingredient.quantity }}
                        </div>
                        <div class="col-2 th-style">
                          {{ ingredient.unit }}
                        </div>
                        <div class="col-7 th-style">
                          {{ ingredient.ingredient.alim_nom_fr }}
                        </div>
                      </div>
                    </div>
                    <div class="col-6 th-style">
                      {{ petitDej[1].petitDejeuner.TextRecette }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Page 3 -->
      <div>
        <div class="partie-div">
          <div class="d-flex flex-row justify-content-between">
            <img class="logo-image" src="../assets/app/logo-version-allongee-baseline-couleur.png" />
            <!--<div class="row partie p-3">Retour page 1</div>-->
            <div>
              <a class="d-flex flex-column justify-content-center align-items-center"
                href="https://www.Leetchi.com/c/mes-meilleurs-menus" target="_blank">
                <p class="p-0 m-0">Je fais un don sur</p>
                <p class="p-0 m-0">Leetchi.com/c/mes-meilleurs-menus</p>
              </a>
            </div>
          </div>
          <div class="row">
            <div class="col-3" style="padding-top: 120px">
              <img src="../assets/collation.png" style="width: 150px; height: 150px; border-radius: 5px" />
            </div>
            <div class="col-9">
              <h1 class="amanise p-3">Mes Collations en fin d'après-midi</h1>
              <p>
                Mes Meilleurs Menus vous offre une sélection variée de
                collations pour chaque jour:
              </p>
              <div class="collation-style">
                <div v-for="jour in semaine" :key="jour" class="jour-collation row p-0 m-0">
                  <div class="col-4 table-header1 d-flex justify-content-center align-items-center">
                    {{ jour }}
                  </div>
                  <div class="col-8">
                    <div v-for="(ingredient, cle) in encas[jour].ingredientsEncas" :key="cle" class="row">
                      <div class="col-3 d-flex justify-content-end">
                        {{ ingredient.quantity }} {{ ingredient.unit }}
                      </div>
                      <div class="col-9 d-flex justify-content-start">
                        {{ ingredient.ingredient.alim_nom_fr }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="partie-div">
          <h1 class="amanise p-4">Mes boissons</h1>
          <p>
            L’eau, la meilleure des boissons, est à consommer à volonté. Si vous
            préférez ne pas consommer l’eau du robinet, alors une eau locale
            comme Wattwiller en Alsace ou Plancoët en Bretagne peuvent
            constituer une alternative, de préférence dans une bouteille
            consignée
          </p>
          <p>
            Le kéfir, de fruit ou de lait, ou le kombucha sont également
            recommandés pour leurs nombreux et divers probiotiques. Les
            probiotiques aident à maintenir la diversité de votre microbiote
            intestinal et comme dans un bon écosystème, elles se nourrissent des
            généreuses quantités de fibres de Mes Meilleurs Menus.
          </p>
          <p>
            Dans le cadre du régime méditerranéen, un verre de vin rouge bio par
            jour peut également être consommé, par exemple un vin de cépage
            pinot noir qui allie des arômes subtils et une bonne concentration
            de polyphénols, connus pour leurs propriétés antioxydantes.
          </p>
        </div>
        <div class="partie-div">
          <h1 class="amanise p-2">Mes condiments</h1>
          <p>
            Du sel avec modération. Herbes fraiches, aromates, ail, oignons ou
            épices à volonté !
          </p>
          <p>
            Pour réaliser Mes Meilleurs Menus au quotidien, nous recommandons de
            constituer une réserve d’épices et d’aromates : Les épices : cumin,
            cannelle, curcuma, curry, poivre, gingembre moulu, paprika, noix de
            muscade, clou de girofle. Les aromates : persil, basilic,
            ciboulette, aneth, herbes de Provence, origan, thym, cerfeuil,
            coriandre, romarin, ail, oignons, échalotes.
          </p>
        </div>

        <div class="partie-div vinaigrette-s" style="margin-top: 100px">
          <div class="d-flex flex-row justify-content-between">
            <img class="logo-image" src="../assets/app/logo-version-allongee-baseline-couleur.png" />
            <!--<div class="row partie p-3">Retour page 1</div>-->
            <div>
              <a class="d-flex flex-column justify-content-center align-items-center"
                href="https://www.Leetchi.com/c/mes-meilleurs-menus" target="_blank">
                <p class="p-0 m-0">Je fais un don sur</p>
                <p class="p-0 m-0">Leetchi.com/c/mes-meilleurs-menus</p>
              </a>
            </div>
          </div>
          <h1 class="amanise p-4">Ma vinaigrette Maison</h1>
          <p>
            Notre proposition de sauce vinaigrette pour la semaine. Idéalement
            pour bénéficier des apports spécifiques de chaque huile, changer
            d'huile chaque semaine et tourner entre l’huile olive, l’huile de
            colza, l’huile de noix et l’huile de lin
          </p>
          <div class="m-2 p-2">
            <div class="row table-header">
              <div class="col-7">
                <div class="row">
                  <div class="col-3 th-style-2">Quantité</div>
                  <div class="col-3 th-style-2">Unité</div>
                  <div class="col-6 th-style-2">Ingrédient</div>
                </div>
              </div>
              <div class="col-5 th-style-2">Astuces</div>
            </div>
            <div>
              <div class="row">
                <div class="col-7">
                  <div v-for="(
                      ingredient, key, index
                    ) in vinaigrette.ingredientsVinaigrette" :key="index" class="row">
                    <div class="col-3 th-style-2">
                      {{ ingredient.quantity }}
                    </div>
                    <div class="col-3 th-style-2">{{ ingredient.unit }}</div>
                    <div class="col-6 th-style-2">
                      {{ ingredient.ingredient.alim_nom_fr }}
                    </div>
                  </div>
                </div>
                <div class="col-5 th-style-2 p-2">
                  {{ vinaigrette.vinaigrette.TextRecette }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- les pages Mes Meuilleurs Menus -->
        <div>
          <div class="d-flex flex-row justify-content-between">
            <img class="logo-image" src="../assets/app/logo-version-allongee-baseline-couleur.png" />
            <!--    <div class="partie p-3">Retour page 1</div>-->
            <div>
              <a class="d-flex flex-column justify-content-center align-items-center"
                href="https://www.Leetchi.com/c/mes-meilleurs-menus" target="_blank">
                <p class="p-0 m-0">Je fais un don sur</p>
                <p class="p-0 m-0">Leetchi.com/c/mes-meilleurs-menus</p>
              </a>
            </div>
          </div>
          <div>
            <h1 class="text-center amanise p-4 my-3">Mes meilleure Menus</h1>
            <div class="m-2 p-2" v-for="(value, key, index) in menus" :key="index">
              <div class="row table-header">
                <div class="col-3 th-style-2">{{ plats[index] }}</div>
                <div class="col-1 th-style-2">Quantité</div>
                <div class="col-1 th-style-2">Unité</div>
                <div class="col-3 th-style-2">{{ value.plat.NomFamPlat }}</div>
                <div class="col-4 th-style-2">
                  {{ value.plat.DurPrep }} - {{ value.plat.Diff }}
                </div>
              </div>
              <div>
                <!-- <div class="row" style="margin: 0;"> -->

                <div class="row">
                  <div class="tangosans text-center p-2">Entrée</div>
                  <div class="col-3">image-entrée</div>
                  <div class="col-9 row">
                    <div class="col-6">
                      <div class="row" v-for="(ingredient, index) in value.ingredientsEntree" :key="index">
                        <div class="col-3 th-style">
                          {{ ingredient.quantity }}
                        </div>
                        <div class="col-2 th-style">
                          {{ ingredient.unit }}
                        </div>
                        <div class="col-7 th-style">
                          {{ ingredient.ingredient.alim_nom_fr }}
                        </div>
                      </div>
                    </div>
                    <div class="col-6 th-style">
                      {{ value.entree.TextRecette }}
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="tangosans text-center p-2">Plat Principal</div>
                  <div class="col-3">image-plat</div>
                  <div class="col-9 row">
                    <div class="col-6">
                      <div class="row" v-for="(ingredient, index) in value.ingredientsPlat" :key="index">
                        <div class="col-3 th-style">
                          {{ ingredient.quantity }}
                        </div>
                        <div class="col-2 th-style">
                          {{ ingredient.unit }}
                        </div>
                        <div class="col-7 th-style">
                          {{ ingredient.ingredient.alim_nom_fr }}
                        </div>
                      </div>
                    </div>
                    <div class="col-6 th-style">
                      {{ value.plat.TextRecette }}
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="tangosans text-center p-2">Dessert</div>
                  <div class="col-3">image-dessert</div>
                  <div class="col-9 row">
                    <div class="col-6">
                      <div class="row" v-for="(
                            ingredient, index
                          ) in value.ingredientsDessert" :key="index">
                        <div class="col-3 th-style">
                          {{ ingredient.quantity }}
                        </div>
                        <div class="col-2 th-style">
                          {{ ingredient.unit }}
                        </div>
                        <div class="col-7 th-style">
                          {{ ingredient.ingredient.alim_nom_fr }}
                        </div>
                      </div>
                    </div>
                    <div class="col-6 th-style">
                      {{ value.dessert.TextRecette }}
                    </div>
                  </div>
                </div>
                <!-- </div> -->
              </div>
            </div>
          </div>
        </div>
        <!-- Couverture en nutritions -->
        <div>
          <div class="d-flex flex-row justify-content-between">
            <img class="logo-image" src="../assets/app/logo-version-allongee-baseline-couleur.png" />
            <!--    <div class="partie p-3">Retour page 1</div>-->
            <div>
              <a class="d-flex flex-column justify-content-center align-items-center"
                href="https://www.Leetchi.com/c/mes-meilleurs-menus" target="_blank">
                <p class="p-0 m-0">Je fais un don sur</p>
                <p class="p-0 m-0">Leetchi.com/c/mes-meilleurs-menus</p>
              </a>
            </div>
          </div>
          <div class="d-flex flex-column text-center">
            <h1>
              Couverture des besoins en fibres, minéraux et vitamines de
              {{ infos.prenom }}{{ members }} avec Mes Meilleurs Menus de la
              semaine
            </h1>
            <h4>
              L’objectif est que les apports de l’alimentation atteignent au
              moins 70% des Valeurs Nutritionnelles de Référence chez l’adulte,
              sans dépasser les Limites Supérieures de Sécurité
            </h4>
          </div>
          <div>
            <div class="row">
              <div class="col-3">
                <p v-if="members_names_Kcal.length == 0">
                  Sur la base de mon profil nutritionnel d’adulte et de mon
                  niveau d’activité physique, mon besoin calorique moyen est de
                  {{ name_Kcal }} kcal.
                </p>
                <p v-else>
                  Sur la base de vos profils nutritionnels et de vos niveaux
                  d’activité physique, le besoin calorique moyen de
                  {{ infos.prenom }} est de {{ name_Kcal }} kcal ({{
                    ((name_Kcal / totalKcal) * 100).toFixed(2)
                  }}%) et de
                  <span v-for="(member, index) in members_names_Kcal" :key="index">
                    {{ member.prenom }} est de {{ member.kcal }} kcal ({{
                      member.percentage
                    }}%)
                  </span>
                  .
                </p>
                <p>
                  Le graphique sur la droite représente la couverture des
                  besoins d’un(e) {{ infos.genre }} adulte en 24 nutriments
                  apportée par Mes Meilleurs Menus cette semaine sur la base de
                  seulement 1800 calories par jour.
                </p>

                <p>
                  Mes Meilleurs Menus sont fournis à titre indicatif et gratuit.
                  L’Association des Meilleurs Mangeurs ne peut être tenue
                  responsable de l’interprétation et de l’utilisation des menus.
                </p>
              </div>
              <div class="col-9">
                <div class="text-center" style="margin-top: 20px">
                  <h4>
                    Pourcentage de couverture des Valeurs Nutritionnelles de
                    Référence par les apports de Mes Meilleurs Menus
                  </h4>
                </div>
                <div class="col-8">
                  <apexchart ref="apexchart" type="bar" height="600" :options="options" :series="[
                    {
                      name: 'Pourcentage',
                      data: mesApportsNutritionnelsHebdo,
                    },
                  ]"></apexchart>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Ma liste de course -->
        <div>
          <div class="d-flex flex-row justify-content-between">
            <img class="logo-image" src="../assets/app/logo-version-allongee-baseline-couleur.png" />
            <!--   <div class="partie p-3">Retour page 1</div>-->
            <div>
              <a class="d-flex flex-column justify-content-center align-items-center"
                href="https://www.Leetchi.com/c/mes-meilleurs-menus" target="_blank">
                <p class="p-0 m-0">Je fais un don sur</p>
                <p class="p-0 m-0">Leetchi.com/c/mes-meilleurs-menus</p>
              </a>
            </div>
          </div>
          <div>
            <h1 class="text-center amanise p-4">
              Ma liste de courses qui va bien pour la semaine
            </h1>
            <h5>
              Voici la liste de courses pour Mes Meilleurs Menus de la semaine,
              y compris les petits déjeuners sucrés.
            </h5>
          </div>
          <div class="container">
            <div class="row p-0 m-0 table-header1" style="border: 1px solid rgb(0, 0, 0)">
              <div class="col-3 p-2 m-0" style="border-right: 1px solid rgb(0, 0, 0)">
                Rayon
              </div>
              <div class="col-9 p-0 m-0">
                <div class="row p-0 m-0">
                  <div class="col-3 p-2">Quantité</div>
                  <div class="col-3 p-2">Unité</div>
                  <div class="col-6 p-2">Aliments</div>
                </div>
              </div>
            </div>
            <div class="row p-0 m-0" v-for="(content, rayon) in listeDeCourses" :key="rayon"
              style="border-right: 1px solid rgb(0, 0, 0)">
              <div v-if="Object.keys(listeDeCourses[rayon]).length != 0" class="div-rayon col-3 p-0 m-0">
                <div class="rayon">
                  {{ rayon }}
                </div>
              </div>
              <div v-if="Object.keys(listeDeCourses[rayon]).length != 0" class="div-rayon col-9 p-0 m-0">
                <div class="row p-0 m-0" v-for="(item, code) in listeDeCourses[rayon]" :key="code">
                  <div class="div-quantity col-3">
                    {{ item.quantity }}
                  </div>
                  <div class="div-unit col-3">
                    {{ item.unit }}
                  </div>
                  <div class="div-aliment col-6">
                    {{ item.alim_nom_fr }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div class="div-class">
            Mes Meilleurs Menus c’est chaque semaine des menus de saison:
          </div>
          <p>▪ savoureux avec des produits rigoureusement sélectionnés</p>
          <p>
            ▪ avec moins de viande et moins d'émissions de gaz à effet de serre
            (-38% d’émission de gaz à effet de serre par rapport à des repas
            classiques ou -10kg de CO2 par personne par semaine)
          </p>
          <p>
            ▪ de type méditerranéen à faible indice glycémique conçus sur la
            base des derniers résultats scientifiques
          </p>
          <p>
            ▪ avec tous les apports nécessaires en 24 nutriments (fibres,
            vitamines et minéraux)
          </p>
          <p>
            ▪ personnalisés qui permettent des apports ajustés aux besoins
            nutritionnels et qui permettent une perte de poids
          </p>
          <p>
            ▪ entièrement gratuits, car TOUT LE MONDE doit pouvoir améliorer son
            alimentation tout en protégeant le climat. Si vous en avez les
            moyens, pour permettre de pérenniser Mes Meilleurs Menus faites un
            don à l’Association des Meilleurs Mangeurs en cliquant ici.
          </p>
        </div>
        <div>
          <div class="div-class">
            Des professionnelles de santé vous aident à mettre Mes Meilleurs
            Menus en pratique
          </div>
          <p>
            Si vous le souhaitez, voici les coordonnées de dietéticien.ne.s qui
            peuvent vous aider au quotidien pour faire entrer Mes Meilleurs
            Menus dans votre vie pour plus de saveurs, pour une meilleure santé
            et un meilleur climat. Toutes proposent des consultations en visio :
            <a href="">Diététiciennes à votre écoute - Mes Meilleurs Menus (mmmenus.fr)
            </a>
          </p>
        </div>
        <div class="div-class text-center">
          Suivez les actualités de Mes Meilleurs Menus sur le site internet :
          <a href="https://www.mmmenus.fr" target="_blank">www.mmmenus.fr</a>
          <a href="https://www.facebook.com/p/Mes-Meilleurs-Menus-100087745325261/" target="_blank"></a>
          sur <a href="https://www.facebook.com/p/Mes-Meilleurs-Menus-100087745325261/">Facebook</a> et <a
            href="https://www.linkedin.com/company/mesmeilleursmenus/">LinkedIn</a> .
          <p class="div-class">A la semaine prochaine !</p>
        </div>
      </div>
    </div>

    <!-- <div class=" d-flex justify-content-center">
            <button class="btn btn-order" @click="generatePDF">Generate PDF</button>
        </div> -->
  </div>
  <!-- </b-modal> -->
</template>

<script>
import { ref, watch } from "vue";
import html2pdf from "html2pdf.js";
// import { forEach } from "core-js/core/array";
export default {
  props: {
    mesApportsNutritionnelsHebdo: {
      type: Array,
      required: true,
    },
    listeDeCourses: {
      type: Object,
      required: true,
    },
    menus: {
      type: Object,
      required: true,
    },
    petitDej: {
      type: Object,
      required: true,
    },
    infos: {
      type: Object,
      required: true,
    },
    triggerDownload: {
      type: Boolean,
      default: false,
    },
    vinaigrette: {
      type: Object,
      required: true,
    },
    encas: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const semaine = [
      "Lundi",
      "Mardi",
      "Mercredi",
      "Jeudi",
      "Vendredi",
      "Samedi",
      "Dimanche",
    ];
    // const series = ref([
    //   {
    //     name: "Pourcentage",
    //     data: props.mesApportsNutritionnelsHebdo,
    //   },
    // ]);
    // plats
    const plats = [
      "Lundi - déjeuner",
      "Lundi - diner",
      "Mardi - déjeuner",
      "Mardi - diner",
      "Mercredi - déjeuner",
      "Mercredi - diner",
      "Jeudi - déjeuner",
      "Jeudi - diner",
      "Vendredi - déjeuner",
      "Vendredi - diner",
      "Samedi - déjeuner",
      "Samedi - diner",
      "Dimanche - déjeuner",
      "Dimanche - diner",
    ];
    // const apexchart = ref(null);

    // Get the current date
    const currentDate = new Date();
    // Define options for formatting
    const options = { day: "numeric", month: "long", year: "numeric" };
    // Create a formatter for the French locale
    const formatter = new Intl.DateTimeFormat("fr-FR", options);
    // Format the current date
    const formattedDate = formatter.format(currentDate);

    // const modalPdf = ref(null);
    // const show = () => {
    //   modalPdf.value.show();
    // };
    const rayons = [
      "Traiteur",
      "Fruits et légumes",
      "Epicerie",
      "Boulangerie",
      "Frais",
      "Boucherie",
      "Charcuterie",
      "Poissonnerie",
      "Œufs",
      "Produits laitiers",
      "Fromagerie",
      "Crèmerie",
      "Boissons",
      "Pâtisserie",
    ];

    // Kcalories needs calculation per members

    const generatePDF = () => {
      const element = document.getElementById("pdf-gen");
      const time = new Date()
      const filename = "Bilan_" + name.value + "_" + time.getFullYear() + time.getMonth() + time.getDay()
      const options = {
        margin: 0.5,
        filename: filename,
        // image: { type: "jpeg", quality: 1.0 },
        jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
        pagebreak: { mode: ["avoid-all", "css", "legacy"] },
        html2canvas: {
          scale: 2,
          //   useCORS: true,
        },
        compressPDF: true,
      };
      html2pdf().from(element).set(options).save();
    };

    const name = ref();
    const familyNames = ref();
    const members = ref();
    const currentYear = new Date().getFullYear();
    // eslint-disable-next-line
    const bodyActivityMap = {
      "0 min": 0,
      "15 min": 0.25,
      "30 min": 0.5,
      "1 heure": 1,
      "1 heure 30 min": 1.5,
      "2 heures": 2,
      "3 heures": 3,
      "4 heures": 4,
    };
    const NAP = (membre) => {
      // This function compute the "Niveau d'activité physique moyen"
      const sommeil = 8;
      const activiteLegere = bodyActivityMap[membre.activite_legere];
      const activiteMoyenne = bodyActivityMap[membre.activite_moyenne];
      const activiteElevee = bodyActivityMap[membre.activite_elevee];
      const activiteAssise =
        24 - (activiteLegere + activiteMoyenne + activiteElevee + sommeil);
      return (
        (sommeil +
          activiteAssise * 1.5 +
          activiteLegere * 2 +
          activiteMoyenne * 3 +
          activiteElevee * 3.5) /
        24
      );
    };
    const HarrisBenedictFormula = (member, NAP) => {
      const coefficients = {
        femme: { P: 9.74, T: 172.9, A: 4.737, C: 667.051 },
        homme: { P: 13.707, T: 492.3, A: 6.673, C: 77.607 },
      };

      const {
        P: poidsCoeff,
        T: tailleCoeff,
        A: ageCoeff,
        C: constanteCoeff,
      } = coefficients[member.genre];
      return (
        (poidsCoeff * member.poids +
          (tailleCoeff * member.taille) / 100 -
          ageCoeff * (currentYear - member.annee_naissance) +
          constanteCoeff) *
        NAP
      );
    };

    const name_Kcal = ref();
    const kcal = ref();
    const totalKcal = ref(0);
    const members_names_Kcal = ref([]);
    watch(
      () => props.triggerDownload,
      (newVal) => {
        if (newVal) {
          name.value = ref(props.infos.prenom);
          // Get all family member names
          familyNames.value = ref(
            props.infos.membres_famille.map((member) => member.prenom)
          );
          // Join all names with a comma and a space
          members.value = ref(
            familyNames.value.length > 0
              ? ", " + familyNames.value.join(", ")
              : ""
          );

          name_Kcal.value = Math.round(
            HarrisBenedictFormula(props.infos, NAP(props.infos))
          );

          // calculate the total kcal needs for all members

          props.infos.membres_famille.forEach((membre) => {
            kcal.value = Math.round(HarrisBenedictFormula(membre, NAP(membre)));
            totalKcal.value += kcal.value;
            members_names_Kcal.value.push({ prenom: membre.prenom, kcal });
          });
          totalKcal.value += name_Kcal.value;
          // calculate each member's percentage of the total kcal needs
          members_names_Kcal.value = members_names_Kcal.value.map((member) => {
            return {
              ...member,
              percentage: ((member.kcal / totalKcal.value) * 100).toFixed(2), // Fixed to 2 decimal places, I think it's better that way
            };
          });
          generatePDF();
        }
      }
    );

    return {
      name,
      name_Kcal,
      members,
      familyNames,
      members_names_Kcal,
      totalKcal,
      semaine,
      plats,
      rayons,
      generatePDF,
      // show,
      // modalPdf,
      formattedDate,
      // Apexchart options
      options: {
        chart: {
          id: "Apport Nutritionnel",
          animations: {
            enabled: false,
            easing: "easein",
            animateGradually: {
              enabled: false,
            },
            dynamicAnimation: {
              enabled: false,
            },
          },
        },
        plotOptions: {
          bar: {
            borderRadius: 4,
            borderRadiusApplication: "end",
            horizontal: true,
            colors: {
              ranges: [
                {
                  from: 0,
                  to: 70,
                  color: "#FFFF00",
                },
                {
                  from: 70.00000001,
                  to: 100000,
                  color: "#004c40",
                },
              ],
            },
          },
        },
        dataLabels: {
          enabled: false,
        },
        xaxis: {
          forceNiceScale: false,
          max: 800,
          categories: [
            "Fibres (g)",
            "Iode (µg)",
            "Magnésium (mg)",
            "Fer (mg)",
            "Cuivre (mg)",
            "Sodium (mg)",
            "Sélénium (µg)",
            "Zinc (mg)",
            "Phosphore (mg)",
            "Kalcium (mg)",
            "Manganèse (mg)",
            "Potassium (mg)",
            "Vitamine K1 (µg)",
            "Vitamine E (mg)",
            "Vitamine D (µg)",
            "Vitamine C (mg)",
            "Vitamine B12 (µg)",
            "Vitamine B9 (µg)",
            "Vitamine B6 (mg)",
            "Vitamine B5 (mg)",
            "Vitamine B3 (mg)",
            "Vitamine B2 (mg)",
            "Vitamine B1 (mg)",
            "Vitamine A (µg)",
          ],
          labels: {
            rotate: -90,
            style: {
              fontFamily: "TangoSans",
              fontSize: "15px",
              fontWeight: "bold",
              color: "#263238",
            },
            formatter: function (val) {
              return val + "%";
            },
          },
        },
        yaxis: {
          labels: {
            style: {
              fontFamily: "TangoSans",
              fontSize: "15px",
              fontWeight: "bold",
              color: "#263238",
            },
          },
        },
      },
    };
  },
};
</script>

<style scoped>
h1 {
  color: #85bb2f;
  font-size: 0.3in;
}

h2 {
  color: #85bb2f;
  font-size: 0.2.3in;
}

.logo-image {
  height: 2cm;
  width: 5cm;
}

.jour {
  font-family: "TangoSans";
  color: #85bb2f;
  text-decoration: underline;
}

.plat-title {
  font-family: "TangoSans";
}

.partie {
  font-family: "TangoSans";
  color: #85bb2f;
  text-decoration: underline;
  padding-bottom: 5px;
}

.breakfast-style {
  min-height: 700px;
}

.collation-style {
  padding: 0;
  border-top: 0.5px solid black;
  border-right: 0.5px solid black;
  border-left: 0.5px solid black;
}

.jour-collation {
  border-bottom: 0.5px solid black;
}

.vinaigrette-s {
  min-height: 1000px;
}

.partie-div {
  display: flex;
  flex-direction: column;
  text-align: center;
  font-family: "TangoSans";
}

.image-plat {
  height: 4cm;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: yellowgreen;
}

.sommaire-image {
  height: 3.28cm;
  width: 3.69cm;
}

.table-header {
  background-color: #d8e4bc;
  padding: 5px;
  text-align: center;
  font-family: "TangoSans";
  font-weight: 300;
  font-size: 0.16666in;
}

.table-header1 {
  background-color: #d8e4bc;
  text-align: center;
  font-family: "TangoSans";
  font-weight: 300;
  font-size: 0.16666in;
}

.rayon {
  display: flex;
  justify-content: center;
  font-size: 25px;
  font-family: "Amanise";
  color: #004c40;
}

.col-3,
.col-6,
.col-9,
p {
  font-size: 12px;
  font-family: "TangoSans";
}

.div-rayon {
  border-right: 0.1px solid black;
  border-left: 0.1px solid black;
  border-bottom: 0.1px solid black;
}

.div-quantity,
.div-unit,
.div-aliment {
  border-bottom: 0.1px solid black;
}

.th-style {
  font-size: 10px;
}

.th-style-2 {
  font-size: 12px;
  font-weight: 300;
  /* text-align: left; */
}

h4 {
  font-weight: 300;
  font-size: 15px;
  font-family: "TangoSans";
}

h5 {
  font-size: 13px;
}

.div-class {
  font-family: "TangoSans";
  font-size: 13px;
  font-weight: 600;
  font-style: italic;
  color: #004c40;
}
</style>
